
/* eslint-disable @typescript-eslint/no-explicit-any */
interface DataWithOptions {
  options: Record<string, any>
}

import { computed, defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'SelectCustom',
  props: {
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Choisissez',
    },
    hasParent: {
      type: String,
    },
    selectUnits: {
      type: Boolean,
      defaut: false,
    },
    data: {
      type: Object as () => DataWithOptions,
      required: true,
    },
  } as any, // eslint-disable-line
  setup(props, ctx) {
    const active = props.data.options
      ? props.data.options.find((o: Record<string, any>) => o.active)
      : ((props.data as unknown) as any[]).find(
          (o: Record<string, any>) => o.active
        )

    const preselected = computed(() => {
      if (props.hasParent && props.data) {
        console.log('test-b', props.hasParent)

        return ((props.data as unknown) as any[]).filter(function(item: any) {
          return item?.slug === props.hasParent
        })
      }

      return null
    })

    const selected = props.data.options
      ? ref(active?.value || props.data.options[0].value)
      : ref(active?.slug || props.hasParent ? preselected.value![0].slug : null) // eslint-disable-line

    const displayLabel = computed(() => {
      if (selected.value) {
        if (props.data.options) {
          return props.data.options.find(
            (o: Record<string, any>) => o.value === selected.value // eslint-disable-line
          ).label
        }

        if (props.selectUnits) {
          return props.data.find(
            (o: Record<string, any>) => o.value === selected.value // eslint-disable-line
          ).label
        }

        return ((props.data as unknown) as any[]).find(
          (o: Record<string, any>) => o.slug === selected.value // eslint-disable-line
        ).name
      }

      return props.placeholder
    })

    const onChange = () => {
      ctx.emit('select', selected.value)
    }

    return {
      displayLabel,
      selected,
      onChange,
      preselected,
    }
  },
})
