<template>
  <div class="select-custom">
    <div class="select-custom__display">
      {{ displayLabel }}
    </div>
    <div class="select-custom__arrow">
      <SvgSprite symbol="ui-arrow-down" size="10 7" />
    </div>

    <template v-if="data.options">
      <select
        class="select-custom__select"
        @change="onChange"
        v-model="selected"
      >
        <option
          v-for="option in data.options"
          :value="option.value"
          :key="`option-${option.value}`"
        >
          {{ option.label }}
        </option>
      </select>
    </template>

    <template v-else>
      <select
        :name="name"
        class="select-custom__select"
        @change="onChange"
        v-model="selected"
      >
        <option value="" disabled selected>{{ placeholder }}</option>
        <option
          v-for="option in data"
          :value="option.slug || option.value"
          :key="`option-${option.slug || option.value}`"
        >
          {{ option.name || option.label }}
        </option>
      </select>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.select-custom {
  position: relative;
  background-color: $c-white;
  border: 1px solid darken($athens-gray, 5%);
}

.select-custom__display {
  padding: $spacing / 2 $spacing * 2.5 $spacing / 2 $spacing * 0.75;
  color: $hippie-blue;
  font-size: 1.3rem;
  line-height: 1.3em;

  @include mq(s) {
    font-size: 1.4rem;
    line-height: 1.4em;
  }
}

.select-custom__arrow {
  @include center-y;
  font-size: 0;
  z-index: 2;
  right: 20px;
  width: 10px;
  height: 8px;
  pointer-events: none;
}

.select-custom__select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  font-size: 1.6rem;
  line-height: 1.2em;
  opacity: 0;
  cursor: pointer;
}
</style>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
interface DataWithOptions {
  options: Record<string, any>
}

import { computed, defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'SelectCustom',
  props: {
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Choisissez',
    },
    hasParent: {
      type: String,
    },
    selectUnits: {
      type: Boolean,
      defaut: false,
    },
    data: {
      type: Object as () => DataWithOptions,
      required: true,
    },
  } as any, // eslint-disable-line
  setup(props, ctx) {
    const active = props.data.options
      ? props.data.options.find((o: Record<string, any>) => o.active)
      : ((props.data as unknown) as any[]).find(
          (o: Record<string, any>) => o.active
        )

    const preselected = computed(() => {
      if (props.hasParent && props.data) {
        console.log('test-b', props.hasParent)

        return ((props.data as unknown) as any[]).filter(function(item: any) {
          return item?.slug === props.hasParent
        })
      }

      return null
    })

    const selected = props.data.options
      ? ref(active?.value || props.data.options[0].value)
      : ref(active?.slug || props.hasParent ? preselected.value![0].slug : null) // eslint-disable-line

    const displayLabel = computed(() => {
      if (selected.value) {
        if (props.data.options) {
          return props.data.options.find(
            (o: Record<string, any>) => o.value === selected.value // eslint-disable-line
          ).label
        }

        if (props.selectUnits) {
          return props.data.find(
            (o: Record<string, any>) => o.value === selected.value // eslint-disable-line
          ).label
        }

        return ((props.data as unknown) as any[]).find(
          (o: Record<string, any>) => o.slug === selected.value // eslint-disable-line
        ).name
      }

      return props.placeholder
    })

    const onChange = () => {
      ctx.emit('select', selected.value)
    }

    return {
      displayLabel,
      selected,
      onChange,
      preselected,
    }
  },
})
</script>
